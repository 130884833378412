/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

//APT theme files
@import "~npm-font-open-sans/open-sans.css";
@import "@archinsurance-da/arch-portal-tools/theme/arch-portal-tools.theme.scss";
//@import "~@progress/kendo-theme-default/dist/all.scss";

body {
  font-family: $FontFamily;
  margin: 0;
  padding: 0;
  color: $text-color;
}

strong {
  font-weight: 600;
}

.comp-wrapper {
  border: 1px solid $border-gray;
  background-color: #fff;
  margin: 0 20px 20px;
  border-radius: 5px;
  padding: 8px;
}

.right-panel {
  background-color: #f9f9f9;
  border-radius: 0 $border-radius 0 0;
  border-left: 1px solid $border-gray;
}

.content-container {
  margin: (-$default-padding) !important;
}
.content {
  padding: $default-padding;
}
.contact-list > div {
  font-weight: 600;
}
